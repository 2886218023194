// import request from '@/utils/requestOss'
import request from '@/utils/request'

//图片库-创建或修改目录
export function createFolder(data) {
    return request({
        url: '/libray/create_folder',
        method: 'post',
        data
    })
}
//--显示目录
export function showFolder(data) {
    return request({
        url: '/libray/show_folder',
        method: 'get',
        params: data
    })
}
//--删除目录
export function delFolder(data) {
    return request({
        url: '/libray/del_folder',
        method: 'post',
        data
    })
}
//显示图片列表
export function showImgList(data) {
    return request({
        url: '/libray/show_img_list',
        method: 'get',
        params: data
    })
}
//获取当前用户剩余空间
export function getInfo(data) {
    return request({
        url: '/libray/img_info',
        method: 'get',
        params: data
    })
}
//删除图片
export function delImg(data) {
    return request({
        url: '/libray/delete_img',
        method: 'post',
        data
    })
}
//图片上传--upload
