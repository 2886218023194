import request from '@/utils/request'

export function shareAdd (data) {
    return request({
        url: '/apply/share/add',
        method: 'post',
        data
    })
}

export function getGoods (data) {
    return request({
        url: '/goods/getGoods',
        method: 'post',
        data
    })
}


export function getCoupon (data) {
    return request({
        url: '/apply/getCoupon',
        method: 'post',
        data
    })
}


export function publish (data) {
    return request({
        url: '/apply/share/publish',
        method: 'post',
        data
    })
}

export function shareDel (data) {
    return request({
        url: '/apply/share/details',
        method: 'post',
        data
    })
}

export function shareUpdate (data) {
    return request({
        url: '/apply/share/update',
        method: 'post',
        data
    })
}

export function shareClear (data) {
    return request({
        url: '/apply/share/del',
        method: 'post',
        data
    })
}

export function packetAdd (data) {
    return request({
        url: '/apply/packet/add',
        method: 'post',
        data
    })
}

export function getPacketTemp (data) {
    return request({
        url: '/apply/getPacketTemp',
        method: 'post',
        data
    })
}

export function packetList (data) {
    return request({
        url: '/apply/packet/list',
        method: 'post',
        data
    })
}

export function packetDel (data) {
    return request({
        url: '/apply/packet/del',
        method: 'post',
        data
    })
}

export function packetHandle (data) {
    return request({
        url: '/apply/packet/handle',
        method: 'post',
        data
    })
}

export function packetDetails (data) {
    return request({
        url: '/apply/packet/details',
        method: 'post',
        data
    })
}

export function packetUpdate (data) {
    return request({
        url: '/apply/packet/update',
        method: 'post',
        data
    })
}

export function dataJump (data) {
    return request({
        url: '/data/jump',
        method: 'post',
        data
    })
}

export function set_take_conf (data) {
    return request({
        url: '/lineup/set_take_conf',
        method: 'post',
        data
    })
}

export function get_take_conf (data) {
    return request({
        url: '/lineup/get_take_conf',
        method: 'post',
        data
    })
}

export function rechargeList (data) {
    return request({
        url: '/apply/recharge/rechargeList',
        method: 'post',
        data
    })
}

export function getMemberList (data) {
    return request({
        url: '/apply/recharge/getMemberList',
        method: 'post',
        data
    })
}

export function rechargecreate (data) {
    return request({
        url: '/apply/recharge/create',
        method: 'post',
        data
    })
}

export function rechargeDel (data) {
    return request({
        url: '/apply/recharge/rechargeDel',
        method: 'post',
        data
    })
}

export function updateRecharge (data) {
    return request({
        url: '/apply/recharge/updateRecharge',
        method: 'post',
        data
    })
}

export function rechargehandle (data) {
    return request({
        url: '/apply/recharge/handle',
        method: 'post',
        data
    })
}

export function mustgetGoods (data) {
    return request({
        url: '/apply/must/getGoods',
        method: 'post',
        data
    })
}

export function setMustConf (data) {
    return request({
        url: '/apply/must/setMustConf',
        method: 'post',
        data
    })
}

export function getMustConf (data) {
    return request({
        url: '/apply/must/getMustConf',
        method: 'post',
        data
    })
}

export function changeMustStatus (data) {
    return request({
        url: '/apply/must/changeMustStatus',
        method: 'post',
        data
    })
}

export function getSeatConf (data) {
    return request({
        url: '/apply/seat/getSeatConf',
        method: 'post',
        data
    })
}

export function setSeatConf (data) {
    return request({
        url: '/apply/seat/setSeatConf',
        method: 'post',
        data
    })
}

export function changeSeatStatus (data) {
    return request({
        url: '/apply/seat/changeSeatStatus',
        method: 'post',
        data
    })
}

export function setRechargeContent (data) {
    return request({
        url: '/apply/recharge/setRechargeContent',
        method: 'post',
        data
    })
}

export function getRechargeContent (data) {
    return request({
        url: '/apply/recharge/getRechargeContent',
        method: 'post',
        data
    })
}